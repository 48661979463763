export class OnAppLoadFailedError extends Error {
	constructor(message, metadata) {
		super(message)
		this.name = 'OnAppLoadFailedError'
		this.code = 30001
		this.message = message || 'Failed to load app - onAppLoad action failed'
		this.metadata = metadata
	}
}

export class ServerError extends Error {
	constructor(serverErrorObject) {
		super(serverErrorObject.message)
		this.name = serverErrorObject.name
		this.code = serverErrorObject.code
		this.message = serverErrorObject.message
		this.metadata = serverErrorObject.metadata
		this.publicMetadata = serverErrorObject.publicMetadata
		this.httpStatusCode = serverErrorObject.httpStatusCode
		this.debug = serverErrorObject.debug
		this.requiredPermissions = serverErrorObject.requiredPermissions
	}
}

/**
 * Model errors
 * Code: 16
 */
export class ModelConfigurationError extends Error {
	constructor(message, metadata) {
		super(message)
		this.name = 'ModelConfigurationError'
		this.code = 12003
		this.httpStatusCode = 500
		this.message = message || 'Model Configuration Error'
		this.metadata = metadata
	}
}

/**
 * Model errors
 * Code: 16
 */
export class UnexpectedDataTypeError extends Error {
	constructor(message, metadata) {
		super(message)
		this.name = 'UnexpectedDataTypeError'
		this.code = 16001
		this.httpStatusCode = 500
		this.message = message || 'Unexpected datatype returned from function'
		this.metadata = metadata
	}
}

/**
 * ActionNode exceptions
 * Code: 17
 */

export class FileAbortException extends Error {
	constructor(message) {
		super(message)
		this.name = 'FileAbortException'
		this.message = message
		this.code = 17018
		this.httpStatusCode = 500
	}
}

export class WebRequestException extends Error {
	constructor(message, metadata, httpStatusCode) {
		super(message)
		this.name = 'WebRequestException'
		this.code = 17003
		this.httpStatusCode = httpStatusCode || 500
		this.message = message
		this.metadata = metadata
	}
}
export class WebRequestTimeoutException extends Error {
	constructor(message, metadata, httpStatusCode) {
		super(message)
		this.name = 'WebRequestTimeoutException'
		this.code = 17005
		this.httpStatusCode = httpStatusCode || 500
		this.message = message
		this.metadata = metadata
	}
}

export class WebRequestAbortedException extends Error {
	constructor(message, metadata, httpStatusCode) {
		super(message)
		this.name = 'WebRequestAbortedException'
		this.code = 17006
		this.httpStatusCode = httpStatusCode || 500
		this.message = message
		this.metadata = metadata
	}
}
export class AppfarmCustomException extends Error {
	constructor(message, metadata, customCode) {
		super(message)
		this.name = 'AppfarmCustomException'
		this.code = 17001
		this.customCode = customCode
		this.httpStatusCode = 500
		this.message = message
	}
}

export class GetUserMediaNotAllowedError extends Error {
	constructor(message, metadata, customCode) {
		super(message)
		this.name = 'GetUserMediaNotAllowedError'
		this.code = 17004
		this.customCode = customCode
		this.httpStatusCode = 500
		this.message = message
	}
}

/**
 * Used for parsing server errors
 */
export class GenericAppfarmError extends Error {
	constructor({ name, code, customCode, message, httpStatusCode, metadata }) {
		super(message)
		this.name = name || 'GenericAppfarmError'
		this.code = code
		this.customCode = customCode
		this.httpStatusCode = httpStatusCode
		this.message = message
		this.metadata = metadata
	}
}

export class ApiRequestError extends Error {
	constructor(message, metadata, httpStatusCode) {
		super(message)
		this.name = 'ApiRequestError'
		this.code = 14006
		this.httpStatusCode = httpStatusCode || 500
		this.message = message || 'Call to API failed'
		this.metadata = metadata
	}
}

// When request is aborted by requester
export class AbortError extends Error {
	constructor(message) {
		super(message)
		this.name = 'AbortError'
		this.message = message
	}
}

// Data Service Error
export class MissingRequiredFieldsError extends Error {
	constructor(message, metadata) {
		super(message)
		this.name = 'MissingRequiredFieldsError'
		this.code = 34003
		this.httpStatusCode = 500
		this.message = message
		this.metadata = metadata
	}
}
