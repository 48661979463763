import axios from 'axios'
import * as Sentry from '@sentry/react'
import isEqual from 'lodash/isEqual'
import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import retry from '@appfarm/common/utils/retry'

import { setMultipleAuthStateValues, resetClient } from '#actions/authActions'
import { validateResource, invalidateClientStatus } from '#actions/resourceStateActions'

const generateRandom = (min = 0, max = 10000) => {
	const difference = max - min
	let rand = Math.random()
	rand = Math.floor(rand * difference)
	rand += min
	return rand
}

const StatusLoader = () => {
	const updateTimer = useRef(null)
	const resourceReady = useSelector((state) => state.resourceReadyState.clientStatus)
	const authState = useSelector((state) => state.authState)
	const dispatch = useDispatch()

	useEffect(() => {
		if (resourceReady) return

		const controller = new AbortController()

		retry(
			(signal) =>
				axios.get('/api/auth/status', {
					signal,
					timeout: 5000,
				}),
			{
				maxTries: 5,
				retryInterval: 1000,
				signal: controller.signal,
				timeout: 7500,
			}
		)
			.then((result) => {
				// Value from client state
				const status = result.data

				if (!status.maintenanceModeEnabled) status.maintenanceModeEnabled = false

				const oldData = {
					userId: authState.userId,
					isLoggedIn: authState.isLoggedIn,
					isAnonymous: authState.isAnonymous,
					maintenanceModeEnabled: authState.maintenanceModeEnabled,
				}

				// Billigere å gjøre dette enn å dispatche
				if (isEqual(oldData, status)) {
					dispatch(validateResource('clientStatus'))
					return
				}

				dispatch(
					setMultipleAuthStateValues({
						...status,
						initialUserStatusApplied: true,
					})
				)

				dispatch(validateResource('clientStatus'))

				Sentry.setUser({ id: status.userId })

				const wasLoggedIn = authState.isLoggedIn
				const userIsChanged = oldData.userId !== status.userId
				// Detect if we lost the session
				if (wasLoggedIn && userIsChanged) {
					dispatch(resetClient())
					window.location.reload()
				}
			})
			.catch((err) => {
				Sentry.captureMessage('Failed to fetch auth status', { extra: { err } })

				// TODO: Detect offline + retry in (?)
			})
			.finally(() => {
				// TODO: Schedule next check
			})

		return () => controller.abort()
	}, [resourceReady, authState])

	/**
	 * Shcedule status update every 10 minutes + offset
	 */
	useEffect(() => {
		const scheduleNext = () => {
			clearTimeout(updateTimer.current)
			updateTimer.current = setTimeout(
				() => {
					dispatch(invalidateClientStatus())
					scheduleNext()
				},
				generateRandom(8 * 60 * 1000, 12 * 60 * 1000)
			)
		}

		scheduleNext()
	}, [])

	return null
}

export default StatusLoader
