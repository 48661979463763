import type { Dispatch } from 'redux'
import isUndefined from 'lodash/isUndefined'

import e_ExecutionMode from '@appfarm/common/enums/e_ExecutionMode'
import type { ActionInstance } from '@appfarm/common/model/logic/Action'
import type { ContextData, EventContext } from '@appfarm/common/types/AppfarmGenerics'
import type AppfarmLogger from '#utils/logger/AppfarmLogger'

import { actionLogger as logger } from '#logger/logger'
import { makeIsActionNotMuted } from '#utils/logger/utils'
import executeBlockInSequence from '../actionNodes/helpers/executeBlockInSequence'
import appController from '../../controllers/appControllerInstance'
import ActionNodeRunner from './ActionNodeRunner'

// TODO: Change when debugger is ported to TS
type ActionDebugger = any

const UNNAMED_ACTION_NAME = 'Unnamed action'

class ActionRunner {
	public id: string
	private actionInstance: ActionInstance // TODO: ActionInstance
	private contextData: ContextData
	private eventContext: any
	private dispatch: Dispatch
	private actionLogger: AppfarmLogger
	private actionNodes: ActionNodeRunner[]

	private actionDebugger: ActionDebugger | null

	constructor(
		actionInstance: ActionInstance,
		contextData: ContextData,
		dispatch: Dispatch,
		getState: any, // TODO: Define state getter
		eventContext: EventContext,
		parentLogger: AppfarmLogger
	) {
		this.id = actionInstance.id
		this.actionInstance = actionInstance
		this.contextData = contextData
		this.dispatch = dispatch // Probably not in use?
		this.eventContext = eventContext

		const currentLogger = parentLogger || logger
		this.actionLogger = currentLogger.createChildLogger(
			{ prefix: `Action: ${this.actionInstance.name}` },
			makeIsActionNotMuted(actionInstance.id)
		)

		// Debugger
		this.actionDebugger = null

		this.actionNodes =
			actionInstance.actionNodes?.map(
				(actionNode, index) =>
					new ActionNodeRunner(
						{ root: this, parent: this },
						index,
						actionNode,
						dispatch,
						getState,
						this.actionLogger
					)
			) || []

		this.attachDebugger = this.attachDebugger.bind(this)
		this._debug_executionRequest = this._debug_executionRequest.bind(this)
	}

	run(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (!this.actionNodes.length) {
				this.actionLogger.error('Could not run Action: No Action Nodes in Action')
				return resolve()
			}
			console.groupEnd()
			// validate actionparams
			const requiredActionParams = this.actionInstance.actionParams?.filter(
				(actionParam) => actionParam.required
			)
			if (requiredActionParams?.length) {
				const actionParamData = this.contextData.action_params || {}

				const missingActionParams = requiredActionParams.reduce((missingList: string[], actionParam) => {
					if (isUndefined(actionParamData[actionParam.id]))
						missingList.push(actionParam.name || 'Unnamed action param')
					return missingList
				}, [])

				if (missingActionParams?.length) {
					this.actionLogger.error(
						'Could not run action - Missing value for required action param(s) ' +
							missingActionParams.join(', ')
					)

					return reject(
						new Error(
							'Could not run action - Missing value for required action param(s) ' +
								missingActionParams.join(', ')
						)
					)
				}
			}
			console.group('Action: ', this.actionInstance.name)
			this.actionLogger.debug(`Run Action: ${this.actionInstance.name}`)
			this.actionLogger.time(this.actionInstance.name || UNNAMED_ACTION_NAME)

			if (!this.actionInstance.allowParallel) appController.setRunningAction(this.actionInstance, this)

			if (this.actionDebugger) {
				this.actionDebugger.actionApi_startAction(this.id)
			}

			if (Object.keys(this.contextData || {}).length) {
				this.actionLogger.context(this.contextData)
			}

			executeBlockInSequence(this.actionNodes, this.contextData)
				.then(() => {
					console.groupEnd()
					this.actionLogger.timeEnd(this.actionInstance.name || UNNAMED_ACTION_NAME)
					if (!this.actionInstance.allowParallel) appController.actionDone(this.actionInstance.id)
					if (this.actionDebugger) {
						this.actionDebugger.actionApi_actionSuccess(this.id)
					}
					resolve()
				})
				.catch((err) => {
					console.groupEnd()
					this.actionLogger.error(`Action failed: ${this.actionInstance.name}`)
					this.actionLogger.timeEnd(this.actionInstance.name || UNNAMED_ACTION_NAME)
					if (!this.actionInstance.allowParallel) appController.actionDone(this.actionInstance.id)

					if (this.actionDebugger) {
						this.actionDebugger.actionApi_actionFailed(this.id, err)
					}
					reject(err)
				})
		})
	}

	attachDebugger(actionDebugger: ActionDebugger): void {
		this.actionDebugger = actionDebugger
	}

	/**
	 * Internal Debug API
	 */
	_debug_executionRequest(actionNodeId: string, actionNodeName: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.actionDebugger
				.actionApi_requestPermissionForExecute(this.id, actionNodeId, {
					actionLogger: this.actionLogger,
					actionName: this.actionInstance.name,
					actionNodeName,
				})
				.then(() => resolve())
				.catch((err: Error) => reject(err))
		})
	}

	_debug_getExecutionModeOverride(actualExecutionMode: e_ExecutionMode): e_ExecutionMode {
		const runSequential = this.actionDebugger.actionApi_breakpointsExists(this.id)
		if (runSequential && actualExecutionMode && actualExecutionMode !== e_ExecutionMode.SEQUENTIAL)
			this.actionLogger.debug(`Changing execution mode from "${actualExecutionMode}" to "sequential".`)

		return runSequential ? e_ExecutionMode.SEQUENTIAL : actualExecutionMode
	}

	_debug_setContextData(actionNodeId: string, contextData: ContextData) {
		this.actionDebugger.actionApi_setContextData(this.id, actionNodeId, contextData)
	}

	_debug_actionNodeSuccess(actionNodeId: string) {
		this.actionDebugger.actionApi_notifyExecutionSuccess(this.id, actionNodeId)
	}

	// TODO: switch with AppfarmError
	_debug_actionNodeFailed(actionNodeId: string, error: Error) {
		this.actionDebugger.actionApi_notifyExecutionFailed(this.id, actionNodeId, error)
	}
}

export default ActionRunner
