import { isNil, isUndefined } from 'lodash'

import { getAllBuiltInPropertiesDict } from '@appfarm/common/builtins/builtInObjectProperties'

import e_Cardinality from '@appfarm/common/enums/e_Cardinality'
import e_SelectionEffectType from '@appfarm/common/enums/e_SelectionEffectType'
import e_ReadObjectsOperation from '@appfarm/common/enums/e_ReadObjectsOperation'
import e_ObjectState from '@appfarm/common/enums/e_ObjectState'
import e_BuiltInObjectPropertyIds from '@appfarm/common/enums/e_BuiltInObjectPropertyIds'
import e_ObjectClassPropertyType from '@appfarm/common/enums/e_ObjectClassPropertyType'

import { runActionNodeOnServer } from '#modules/afClientApi'
import objectSorter from '#utils/objectSorter'
import { sliceData } from '../../utils/sliceData'

const p_readObjects = ({ actionNode, contextData, actionNodeRunner, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		const targetDataSource = appController.getDataSource(actionNode.targetDataSourceId)
		if (!targetDataSource) return reject(new Error('Read objects failed: Could not find target dataSource'))

		const readData = (targetDataSource) =>
			new Promise((resolve, reject) => {
				let getObjectsForReadPromise

				if (actionNode.readFromOtherDataSource) {
					if (!actionNode.sourceDataBinding || !actionNode.sourceDataBinding.dataSourceId)
						return reject(new Error('Unable to find source dataSource'))

					const sourceDataSource = appController.getDataSourceFromDataBindingProperty(
						actionNode.sourceDataBinding
					)

					if (actionNode.sourceDataBinding.propertyId) {
						getObjectsForReadPromise = () =>
							Promise.resolve(
								appController.getObjectsFromDataBindingProperty(actionNode.sourceDataBinding, {
									contextData,
								})
							)
					} else {
						if (!sourceDataSource) return reject(new Error('Unable to find source dataSource'))

						if (sourceDataSource.objectClassId !== targetDataSource.objectClassId)
							return reject(new Error('readObjects: Cannot read from dataSource of different type'))

						if (sourceDataSource.dataConnector) {
							const rootActionId = actionNodeRunner.getRootAction().id

							getObjectsForReadPromise = () =>
								sourceDataSource.p_getFilteredObjects({
									contextData,
									actionId: rootActionId,
									actionNodeId: actionNode.id,
								})
						} else {
							let selectionType = actionNode.selectionType
							if (sourceDataSource.cardinality === e_Cardinality.ONE)
								selectionType = e_SelectionEffectType.ALL

							getObjectsForReadPromise = () =>
								Promise.resolve(
									sourceDataSource.getObjectsBySelectionType({
										selectionType: selectionType,
										staticFilter: actionNode.staticFilter,
										filterDescriptor: actionNode.filterDescriptor,
										actionName: actionNode.name,
										contextData,
									})
								)
						}
					}

					getObjectsForReadPromise().then((objectsForRead) => {
						if (objectsForRead === null)
							return reject(new Error('Unable to get objects for read - check filter and selection settings'))

						if (sourceDataSource) {
							const builtinDataSourcePropertiesDict = getAllBuiltInPropertiesDict()
							const sourcePropertiesByNodeName = Object.values(sourceDataSource.propertiesMetaDict).reduce(
								(dict, property) => {
									dict[property.nodeName] = property
									return dict
								},
								{}
							)

							// clean values
							objectsForRead = objectsForRead.map((item) => {
								const cleanedItem = {}
								Object.values(targetDataSource.propertiesMetaDict)
									.filter(
										(property) =>
											(!builtinDataSourcePropertiesDict[property.id] ||
												property.id === e_BuiltInObjectPropertyIds.OBJECT_STATE) &&
											!isUndefined(item[property.nodeName])
									)
									.forEach((property) => {
										if (property.runtime) {
											// allow a runtime property to be transferred if source and target share nodename and datatype
											if (
												property.propertyType !== e_ObjectClassPropertyType.FUNCTION &&
												property.dataType === sourcePropertiesByNodeName[property.nodeName]?.dataType
											) {
												cleanedItem[property.nodeName] = item[property.nodeName]
											}
										} else {
											cleanedItem[property.nodeName] = item[property.nodeName]
										}
									})

								// also read runtime file-data if applicable
								if (targetDataSource.isFileObjectClass && item.__file) cleanedItem.__file = item.__file
								return cleanedItem
							})

							// set object state if read from database connected datasource (local datasources should already have state)
							if (!sourceDataSource.local) {
								objectsForRead = objectsForRead.map((item) => ({
									...item,
									[e_BuiltInObjectPropertyIds.OBJECT_STATE]: e_ObjectState.SYNCHRONIZED,
								}))
							}

							if (actionNode.sorting && actionNode.sorting.length)
								objectsForRead = objectSorter(objectsForRead, actionNode.sorting)

							if (
								(!isNil(actionNode.resultLimit) || !isNil(actionNode.skip)) &&
								targetDataSource.cardinality === e_Cardinality.MANY
							) {
								const skip = actionNode.skip
									? appController.getDataFromDataValue(actionNode.skip, contextData)
									: 0
								const limit =
									actionNode.resultLimit &&
									appController.getDataFromDataValue(actionNode.resultLimit, contextData)
								objectsForRead = sliceData({ skip, limit, data: objectsForRead })
							}
						}

						if (targetDataSource.cardinality === e_Cardinality.ONE && objectsForRead.length > 1) {
							objectsForRead = sliceData({ skip: 0, limit: 1, data: objectsForRead })
						}

						if (targetDataSource.reverseDependencies && targetDataSource.reverseDependencies.length) {
							let selectionData = [...objectsForRead]
							if (actionNode.operation === e_ReadObjectsOperation.ADD) {
								// has to send all dependand data to server, so if ADD - also include existing objects
								// should be optimized to only send dependent / relevant data.
								const existingObjects = targetDataSource.getAllObjects()
								selectionData = [...existingObjects, ...objectsForRead]
							}
							const rootActionId = actionNodeRunner.getRootAction().id
							runActionNodeOnServer(rootActionId, actionNode.id, { data: selectionData })
								.then((result) => resolve({ data: objectsForRead, dependencies: result }))
								.catch((err) => reject(err))
						} else {
							resolve({ data: objectsForRead, dependencies: {} })
						}
					})
				} else {
					const rootActionId = actionNodeRunner.getRootAction().id
					runActionNodeOnServer(rootActionId, actionNode.id, { contextData })
						.then((result) => {
							if (!result.data) return resolve(result)
							const data = result.data.map((item) => ({
								...item,
								[e_BuiltInObjectPropertyIds.OBJECT_STATE]: e_ObjectState.SYNCHRONIZED,
							}))
							return resolve({ ...result, data })
						})
						.catch((err) => reject(err))
				}
			})

		readData(targetDataSource)
			.then((result) => {
				const objectsForRead = result.data || []
				const sideEffects = result.dependencies

				actionNodeLogger.debug('SideEffects: ', { payload: sideEffects })

				targetDataSource
					.p_insertReadObjects({
						objects: objectsForRead,
						readObjectOperation: actionNode.operation || e_ReadObjectsOperation.REPLACE,
						sideEffects,
						logger: actionNodeLogger,
					})
					.then(() => {
						if (targetDataSource.cardinality === e_Cardinality.MANY && actionNode.setSelected) {
							const fileIds = objectsForRead.map((item) => item._id)
							if (actionNode.operation !== e_ReadObjectsOperation.ADD) {
								actionNodeLogger.debug('Selecting all objects')
								targetDataSource
									.p_selectAll()
									.then(() => {
										resolve()
									})
									.catch(reject)
							} else {
								actionNodeLogger.debug('Selecting added objects')
								targetDataSource
									.p_filteredSelection({
										staticFilter: { _id: { $in: fileIds } },
										keepExistingSelection: true,
									})
									.then(() => {
										resolve()
									})
									.catch(reject)
							}
						}
						console.groupCollapsed('Successfully read objects: ', objectsForRead.length)
						actionNodeLogger.debug('Successfully read objects: ' + objectsForRead.length)
						actionNodeLogger.table(objectsForRead, null, { dataSourceId: targetDataSource.id })
						console.groupEnd()
						resolve()
					})
					.catch(reject)
			})
			.catch((err) => reject(err))
	})

export default p_readObjects
