import Papa from 'papaparse'
import ObjectID from 'bson-objectid'
import axios from 'axios'
import { isUndefined, isString, isPlainObject } from 'lodash'

import { e_ImportDataSourceFormat, e_ImportDataSourceType } from '@appfarm/common/enums/e_PropertyTypes'
import e_ReadObjectsOperation from '@appfarm/common/enums/e_ReadObjectsOperation'
import e_ObjectClassDataType from '@appfarm/common/enums/e_ObjectClassDataType'
import e_ActionNodeSelectionType from '@appfarm/common/enums/e_ActionNodeSelectionType'
import e_FunctionParameterType from '@appfarm/common/enums/e_FunctionParameterType'

import dataTypeParser from '@appfarm/common/utils/dataTypeParser'
import jsonDataMapper from '@appfarm/common/utils/jsonDataMapper'
import { FILE_UPLOAD_REJECT_TIMEOUT } from '@appfarm/common/constants/fileUploads'

import evaluateFunctionValue from '#utils/functionEvaluator'
import dayjs from '#controllers/dayjs'

import { FileAbortException } from '#utils/clientErrors'
import { getSideEffects } from '../../modules/afClientApi'
import getDataSourceDataFromJSONValueMap from './helpers/getDataForDSFromJSONValueMap'

const p_importDataFromFile = ({ actionNode, contextData, appController, actionNodeLogger }) => {
	const runResultParser = ({ data, resultParser }) => {
		if (!resultParser) return data

		actionNodeLogger.debug('Raw data: ' + data.length, { payload: { data } })

		const resultParameters = [
			{
				functionParameterType: e_FunctionParameterType.CONSTANT,
				name: 'rawResponseData', // defined in Model. Do not change.
				value: data,
			},
			{
				functionParameterType: e_FunctionParameterType.CONSTANT,
				name: 'generateObjectId', // defined in Model. Do not change.
				value: () => new ObjectID().toString(),
			},
		]

		const parsedData = evaluateFunctionValue({
			ignoreReturnDatatypeCheck: true,
			appController: appController,
			contextData: contextData, // Old object in context
			reThrowError: true,
			functionValue: {
				...resultParser,
				functionParameters: [...(resultParser.functionParameters || []), ...resultParameters],
			},
		})

		actionNodeLogger.debug('Parsed data: ' + parsedData.length, { payload: { data: parsedData } })

		return parsedData
	}

	const importDataFromCSV = ({ data, resolve, reject }) => {
		if (!actionNode.dataSourceId) return reject(new Error('Could not import file - no target datasource'))
		const dataSource = appController.getDataSource(actionNode.dataSourceId)

		if (!dataSource) return reject(new Error('Unable to find target datasource'))

		let delimiter = actionNode.delimiter
		if (delimiter === '\\t') delimiter = '\t'

		const importColumns = actionNode.importColumns || []
		return Papa.parse(data, {
			quoteChar: '"',
			delimiter: delimiter,
			skipEmptyLines: true,
			complete: (results) => {
				if (results.errors.length) {
					const payload = { errors: results.errors }
					if (results.meta)
						payload.meta = { delimiter: results.meta.delimiter, linebreak: results.meta.linebreak }
					actionNodeLogger.error('Unable to parse data for import: ', { payload: payload })
					const error = new Error('Unable to parse data for import')
					error.metadata = { responseData: payload }
					return reject(error)
				}

				const resultData = actionNode.resultParser
					? runResultParser({ data: results.data, resultParser: actionNode.resultParser })
					: results.data

				const allObjects = resultData.reduce((allObjects, row, index) => {
					if (index === 0 && actionNode.ignoreFirstRow) return allObjects

					if (importColumns.length > row.length) {
						actionNodeLogger.warning('Found datarow with less columns than specified in the import.')
					}

					const importObject = importColumns.reduce((importObject, columnDef, index) => {
						if (columnDef.dataBinding.nodeName) {
							const propertyMeta = dataSource.getPropertyMetaData(columnDef.dataBinding.propertyId)
							const options = {}
							if (propertyMeta.dataType === e_ObjectClassDataType.DATE && columnDef.dateFormat) {
								options.dateFormat = columnDef.dateFormat
							}
							if (propertyMeta.dataType === e_ObjectClassDataType.FLOAT && columnDef.decimalSeparator) {
								options.decimalSeparator = columnDef.decimalSeparator
							}

							let value = row[index]

							if (isUndefined(value)) {
								actionNodeLogger.warning(
									`Undefined value was found for column ${columnDef.name}. This may happen if the delimiter is auto-detected poorly. If possible, specify the delimiter in the action node.`
								)
								value = ''
							}

							if (propertyMeta.dataType === e_ObjectClassDataType.ENUM) {
								const enumType =
									columnDef.dataBinding.enumeratedTypeId &&
									appController.getEnumeratedType(columnDef.dataBinding.enumeratedTypeId)
								const entry = enumType.valueDict[value.trim()]
								if (entry) {
									importObject[columnDef.dataBinding.nodeName] = entry.value
								} else {
									importObject[columnDef.dataBinding.nodeName] = undefined
								}
							} else if (propertyMeta.dataType === e_ObjectClassDataType.REFERENCE) {
								let matches = []
								if (columnDef.referenceMappingProperty) {
									const mappingDataSource = appController.getDataSource(
										columnDef.referenceMappingProperty.dataSourceId
									)
									const mappingPropertyMeta = mappingDataSource.getPropertyMetaData(
										columnDef.referenceMappingProperty.propertyId
									)
									matches = mappingDataSource.getObjectsBySelectionType({
										selectionType: e_ActionNodeSelectionType.FILTERED,
										staticFilter: { [mappingPropertyMeta.nodeName]: value },
									})
								}

								if (matches && matches.length === 1) {
									importObject[columnDef.dataBinding.nodeName] = matches[0]._id
								} else {
									importObject[columnDef.dataBinding.nodeName] = undefined
									if (matches && matches.length > 0) {
										actionNodeLogger.warning(
											`More than one match found for column ${columnDef.name} for value ${value}:`,
											{ payload: matches }
										)
										// actionNodeLogger.table(matches)
									} else if (!columnDef.referenceMappingProperty) {
										actionNodeLogger.warning(
											`Could not resolve reference mapping property for column ${columnDef.name}.`
										)
									} else {
										actionNodeLogger.warning(`No match found for column ${columnDef.name} for value ${value}`)
									}
								}
							} else {
								options.dayjs = dayjs
								importObject[columnDef.dataBinding.nodeName] = dataTypeParser(
									value,
									propertyMeta.dataType,
									options
								)
							}
						} else {
							return reject(new Error('Unable to import - invalid databinding on column'))
						}
						return importObject
					}, {})

					const emptyObject = dataSource.generateNewObject()

					allObjects.push({
						...emptyObject,
						...importObject,
					})
					return allObjects
				}, [])

				let sideEffectPromise = Promise.resolve({})

				if (dataSource.reverseDependencies.length) {
					sideEffectPromise = getSideEffects(actionNode.dataSourceId, { objectsAdded: allObjects }, [])
				}

				sideEffectPromise
					.then((sideEffects) =>
						dataSource.p_insertReadObjects({
							objects: allObjects,
							sideEffects: sideEffects,
							readObjectOperation: e_ReadObjectsOperation.REPLACE,
							logger: actionNodeLogger,
						})
					)
					.then(() => {
						console.groupCollapsed('Imported objects: ', allObjects.length)
						actionNodeLogger.debug('Imported objects: ' + allObjects.length)
						actionNodeLogger.table(allObjects, null, { dataSourceId: actionNode.dataSourceId })
						console.groupEnd()
						resolve()
					})
					.catch(reject)
			},
		})
	}

	const parseData = ({ data }) => {
		let parsedJsonData
		// Map Result
		if (actionNode.resultMapping && actionNode.resultMapping.length) {
			parsedJsonData = actionNode.resultMapping.reduce((parsedJsonData, item) => {
				const mappedDataSource = jsonDataMapper(item, data)
				return {
					...parsedJsonData,
					...mappedDataSource,
				}
			}, {})
		}

		// Clean the result
		const dataForDataSource = getDataSourceDataFromJSONValueMap({
			valueMaps: actionNode.resultMapping,
			appController,
			parsedJsonData,
			logger: actionNodeLogger,
		})

		appController.replaceOrAddDataInMultipleDataSources(dataForDataSource, actionNodeLogger)

		if (isPlainObject(parsedJsonData)) {
			console.groupCollapsed('Imported objects: ')
			Object.entries(parsedJsonData).forEach(([dataSourceId, data]) => {
				actionNodeLogger.table(data, null, { dataSourceId })
			})
			console.groupEnd()
		}
	}

	const importDataFromJSON = ({ data, resolve }) => {
		data = JSON.parse(data)
		if (actionNode.resultParser) {
			data = runResultParser({ data, resultParser: actionNode.resultParser })
		}

		parseData({ data })
		resolve()
	}

	const importDataFromText = ({ data, resolve }) => {
		if (!actionNode.resultParser) {
			throw new Error('Expected Result Parser to be configured.')
		}

		data = runResultParser({ data: data, resultParser: actionNode.resultParser })
		parseData({ data })
		resolve()
	}

	const importData = ({ format, data, resolve, reject }) => {
		if (format === e_ImportDataSourceFormat.JSON) {
			if (data && typeof data !== 'string') {
				return data
					.text()
					.then((data) => {
						return importDataFromJSON({ data, resolve })
					})
					.catch(reject)
			} else {
				return importDataFromJSON({ data, resolve })
			}
		} else if (format === e_ImportDataSourceFormat.CSV) {
			return importDataFromCSV({ data, resolve, reject })
		} else if (format === e_ImportDataSourceFormat.TEXT) {
			if (data && typeof data !== 'string') {
				return data
					.text()
					.then((data) => importDataFromText({ data, resolve }))
					.catch(reject)
			} else {
				return importDataFromText({ data, resolve })
			}
		}
	}

	return new Promise((resolve, reject) => {
		const format = actionNode.sourceFormat || e_ImportDataSourceFormat.CSV

		if (actionNode.sourceType === e_ImportDataSourceType.TEXT) {
			if (!actionNode.source) return reject(new Error('Unable to find source text'))

			const text = appController.getDataFromDataValue(actionNode.source, contextData) || ''

			return importData({ format, data: text, resolve, reject })
		} else if (actionNode.sourceType === e_ImportDataSourceType.URL) {
			// Create config for axios
			const config = {
				url: appController.getDataFromDataValue(actionNode.source, contextData) || '',
				method: 'get',
				responseType: 'blob',
			}

			if (!isString(config.url)) return reject(new Error('URL must be a string'))
			if (!config.url) return reject(new Error('No URL specified'))

			actionNodeLogger.debug('Getting content from url: ' + config.url)

			axios
				.request(config)
				.then((response) => importData({ format, data: response.data, resolve, reject }))
				.catch(reject)
		} else {
			const fileInput = document.createElement('input')
			fileInput.setAttribute('type', 'file')
			if (format === e_ImportDataSourceFormat.CSV) fileInput.setAttribute('accept', '.csv, .txt, .text')

			if (!fileInput) return reject(new Error('importDataFromFile: Unable to find input element'))

			const previousOnfocusEvent = document.body.onfocus
			let rejectTimer = null
			fileInput.onchange = (event) => {
				clearTimeout(rejectTimer)
				document.body.onfocus = previousOnfocusEvent
				const files = fileInput.files

				if (files.length !== 1) return

				return importData({ format, data: files[0], resolve, reject })
			}

			document.body.onfocus = () => {
				rejectTimer = setTimeout(() => {
					document.body.onfocus = previousOnfocusEvent
					reject(new FileAbortException('File Import aborted'))
				}, FILE_UPLOAD_REJECT_TIMEOUT)
			}

			fileInput.click()
		}
	})
}

export default p_importDataFromFile
