import { isPlainObject, isArray } from 'lodash'

import e_FilterTargetSelectionMode from '@appfarm/common/enums/e_FilterTargetSelectionMode'
import e_BuiltInObjectPropertyIds from '@appfarm/common/enums/e_BuiltInObjectPropertyIds'
import e_UiComponentType from '@appfarm/common/enums/e_UiComponentType'
import { e_ContainerIterationVariant } from '@appfarm/common/enums/e_PropertyTypes'

const addNodeNameToDict = (dict, component) => {
	if (component.nodeName) dict[component.nodeName] = true
	if (component.sourceNodeName) dict[component.sourceNodeName] = true
	if (component.targetNodeName) {
		dict[component.targetNodeName] = true
		if (component.targetSelectionMode) {
			if (component.targetSelectionMode === e_FilterTargetSelectionMode.SELECTED) {
				dict[e_BuiltInObjectPropertyIds.IS_SELECTED] = true
			} else if (component.targetSelectionMode === e_FilterTargetSelectionMode.UNSELECTED) {
				dict[e_BuiltInObjectPropertyIds.IS_NOT_SELECTED] = true
			}
		}
	}
	if (component.nodeNamesInUse) {
		// Handle implicitly referenced node names in function expressions and scripts
		// See: functionParameterInflator and codedComponentInflator
		for (const nodeName of component.nodeNamesInUse) {
			dict[nodeName] = true
		}
	}
}

const addDataSourcesToDict = (dict, component) => {
	if (component.dataSourceId) {
		if (!dict[component.dataSourceId]) dict[component.dataSourceId] = {}
		addNodeNameToDict(dict[component.dataSourceId], component)
	}

	if (component.dataSource?.dataSourceId) {
		if (!dict[component.dataSource.dataSourceId]) dict[component.dataSource.dataSourceId] = {}
		addNodeNameToDict(dict[component.dataSource.dataSourceId], component)
	}

	if (component.sourceDataSourceId) {
		if (!dict[component.sourceDataSourceId]) dict[component.sourceDataSourceId] = {}
		addNodeNameToDict(dict[component.sourceDataSourceId], component)
	}

	if (component.targetDataSourceId) {
		if (!dict[component.targetDataSourceId]) dict[component.targetDataSourceId] = {}
		addNodeNameToDict(dict[component.targetDataSourceId], component)
	}

	if (component.dataSourcesInUse) {
		Object.entries(component.dataSourcesInUse).forEach(([dataSourceId, nodeNamesDict]) => {
			dict[dataSourceId] = {
				...dict[dataSourceId],
				...nodeNamesDict,
			}
		})
	}
}

/**
 * Need to determine when a component is dependent on selection change.
 */
const findDataSourceIdsRecursive = (dict, component) => {
	if (isPlainObject(component)) {
		addDataSourcesToDict(dict, component)

		Object.keys(component).forEach((key) => {
			findDataSourceIdsRecursive(dict, component[key])
		})
	} else if (isArray(component)) {
		component.forEach((item) => findDataSourceIdsRecursive(dict, item))
	}
}

const isRecursiveIteratorContainer = (component) =>
	component.componentType === e_UiComponentType.CONTAINER &&
	component.iterationVariant === e_ContainerIterationVariant.TREE

const processSingleComponent = (component, level) => {
	const dataSourcesInUse = {}
	// Check top level
	addDataSourcesToDict(dataSourcesInUse, component)

	Object.keys(component).forEach((key) => {
		if (key === 'children') return // Process later
		if (component.componentType === e_UiComponentType.VIEW && key === 'appBar') return // Process later

		// Hydrate dict
		findDataSourceIdsRecursive(dataSourcesInUse, component[key])
	})

	if (
		component.componentType === e_UiComponentType.VISIBILITY_GROUP ||
		isRecursiveIteratorContainer(component)
	) {
		component.children &&
			component.children.forEach((childComponent) => {
				if (!childComponent.visible) return
				findDataSourceIdsRecursive(dataSourcesInUse, childComponent.visible)
			})
	}

	component.__dataSourcesInUse = dataSourcesInUse
	component.__isDataDependent = !!Object.keys(dataSourcesInUse).length
	component.__nestingLevel = level

	// Process children as well
	if (component.children) {
		component.children.forEach((item) => processSingleComponent(item, level + 1))
	}
	if (component.componentType === e_UiComponentType.VIEW && component.appBar) {
		processSingleComponent(component.appBar, 0) // set to 0 since we set it to 0 in appbarConnector
	}
}

const attachDataSourceIdsToComponents = (viewList) => {
	// console.time('Process Views')
	viewList.forEach((item) => processSingleComponent(item, 0))
	// console.timeEnd('Process Views')
	return viewList
}

export default attachDataSourceIdsToComponents
