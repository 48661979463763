/******************************************************************************
 *
 * This file will load JS-functions for use by the application
 * TODO: In preprocessing, only extract functions that will run client side.
 *
 *****************************************************************************/
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'

import retry from '@appfarm/common/utils/retry'

import { setFunctionsChecksum } from '#actions/metadataActions'

const ScriptLoader = ({ activeAppId, appController }) => {
	const functionsChecksum = useSelector((state) => state.metaData.wantedChecksums.functions)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!activeAppId) return
		if (!functionsChecksum) return

		appController.setFunctionsPendingFlag(true)

		const controller = new AbortController()

		let element

		const cleanup = () => {
			if (element) {
				document.head.removeChild(element)
				element = null
			}
		}

		retry(
			() =>
				new Promise((resolve, reject) => {
					element = document.createElement('script')
					element.src = `/api/v1/apps/${activeAppId}/metadata/functions?v=${functionsChecksum}`
					element.id = 'fn_' + activeAppId
					document.head.appendChild(element)

					// When script is ready
					element.onload = resolve
					element.onerror = (err) => {
						cleanup()
						reject(err)
					}
				}),
			{
				maxTries: 3,
				retryInterval: 1000,
				signal: controller.signal,
				// Note: Long timeout as transfer time is impacted by model size
				timeout: 45000,
			}
		)
			.then(() => {
				if (controller.signal.aborted) return

				appController.setFunctionsPendingFlag(false)
				dispatch(setFunctionsChecksum(functionsChecksum))
			})
			.catch((err) => {
				if (controller.signal.aborted) return

				Sentry.captureMessage('Failed to fetch functions', { extra: { err } })
			})

		return () => {
			controller.abort()
			cleanup()
			dispatch(setFunctionsChecksum(null))
		}
	}, [activeAppId, functionsChecksum])

	return null
}

ScriptLoader.propTypes = {
	activeAppId: PropTypes.string,
}

export default ScriptLoader
