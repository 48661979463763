/******************************************************************************
 *
 * Loader for AppList
 *
 *****************************************************************************/
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'

import retry from '@appfarm/common/utils/retry'

import { setAppList } from '#actions/metadataActions'
import e_ScreenSize from '@appfarm/common/enums/e_ScreenSize'

const getScreenSize = () => {
	const width = window.innerWidth
	if (width <= 599) return e_ScreenSize.EXTRA_SMALL
	if (width <= 1239) return e_ScreenSize.SMALL
	if (width <= 1439) return e_ScreenSize.MEDIUM
	return e_ScreenSize.LARGE
}

const AppListLoader = () => {
	const isLoggedIn = useSelector((state) => state.authState.isLoggedIn)
	const wantedChecksum = useSelector((state) => state.metaData.latestGlobalChecksums.appList)
	const [loadedChecksum, setLoadedChecksum] = useState(null)

	const dispatch = useDispatch()

	useEffect(() => {
		if (!wantedChecksum) return
		if (wantedChecksum === loadedChecksum) return
		if (!isLoggedIn) return // Both anonymous and authenticated

		const controller = new AbortController()

		retry(
			(signal) =>
				axios.get(`/api/v1/apps?v=${wantedChecksum}`, {
					signal,
					timeout: 5000,
				}),
			{
				maxTries: 5,
				retryInterval: 1000,
				signal: controller.signal,
				timeout: 7500,
			}
		)
			.then((result) => {
				let appList = result.data
				const screenSize = getScreenSize()
				appList = appList.map((item) => {
					if (item?.screenSizeAvailability?.[screenSize] || item?.error?.type === 'NoAccess') {
						return item
					} else {
						return {
							id: item.id,
							readableId: item.readableId,
							error: {
								type: 'UnsupportedDevice',
							},
						}
					}
				})

				dispatch(setAppList(appList))
				setLoadedChecksum(wantedChecksum)
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				Sentry.captureMessage('Failed to get app list', { extra: { err } })
			})

		return () => controller.abort()
	}, [isLoggedIn, wantedChecksum, loadedChecksum])

	return null
}

export default AppListLoader
