import { forEachEnvelopeItem } from '@sentry/utils'
import type { Transport, Envelope, EnvelopeItemType } from '@sentry/types'

class DevLoggingTransport implements Transport {
	flush = async () => true
	send = async (envelope: Envelope) => {
		let eventType: EnvelopeItemType | undefined
		const logQueue: unknown[] = []
		forEachEnvelopeItem(envelope, (item, type) => {
			eventType ??= type
			logQueue.push(item[1])
		})

		console.debug(
			`%c△%c Sentry %c${eventType}%c:`,
			'color:#e1567c',
			'color:inherit',
			'font-weight:bolder',
			'font-weight:inherit',
			...logQueue
		)

		return {}
	}
}

export default function makeDevLoggingTransport() {
	return new DevLoggingTransport()
}
